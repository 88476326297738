<template>
  <div>
    <div class="vx-row mb-3">
      <div class="vx-col lg:w-9/12 w-full">
        <vx-card :is-refresh="isLoadingInitData">
          <vs-tabs alignment="fixed" v-model="activeTab">
            <vs-tab label="Detail Staf" icon-pack="feather">
              <TabHeader :isActive="activeTab === 0" :initData="initData"/>
            </vs-tab>
            <vs-tab label="Detail Komisi" icon-pack="feather">
              <TabKomisi :isActive="activeTab === 1" :initData="initData"/>
            </vs-tab>
            <vs-tab label="Status Pembayaran" icon-pack="feather">
              <TabPayment :isActive="activeTab === 2" :initData="initData"/>
            </vs-tab>
          </vs-tabs>
        </vx-card>
      </div>

      <div class="vx-col lg:w-3/12 w-full mt-3 lg:mt-0">
        <vx-card :is-refresh="isLoadingInitData">
          <div class="flex items-center mb-base">
            <feather-icon icon="LayersIcon" svgClasses="h-5 w-5" class="mr-2"/>
            <h5 class="font-medium">Approval Progress</h5>
          </div>
          <ApprovalProgress
            ref="approvalProgress"
            type="KOMISI"
            :id-ref="$route.params.idKomisiPayment"
            :isShowSigningAction="true"
            @signingClicked="onClickSigningAction"/>
        </vx-card>
      </div>
    </div>

    <!--modals-->
    <KomisiApprovalSigning
      :isActive.sync="modalSigning.active"
      :idKomisiPayment="modalSigning.idKomisiPayment"
      :status="modalSigning.status"
      @success="onSigned"/>
  </div>
</template>

<script>
import moduleKomisiApproval from '@/store/modules/approvals/komisi-approval/komisi-approval.store'
import KomisiRepository from '@/repositories/marketing/komisi-repository'

export default {
  name: 'KomisiApprovalShow',
  components: {
    KomisiApprovalSigning: () => import('@/views/pages/approvals/komisi-approval/KomisiApprovalSigning'),
    TabHeader: () => import('@/views/pages/approvals/komisi-approval/parts/TabStaf'),
    TabKomisi: () => import('@/views/pages/approvals/komisi-approval/parts/TabKomisi'),
    TabPayment: () => import('@/views/pages/approvals/komisi-approval/parts/TabPayment'),
    ApprovalProgress: () => import('@/views/components/approval-progress/ApprovalProgress')
  },
  mounted () {
    this.getInitData()
  },
  data () {
    return {
      isLoadingInitData: false,
      initData: {
        komisiPayment: {},
        staf: {},
        komisi: {},
        payments: []
      },
      modalSigning: {
        active: false,
        idKomisiPayment: this.$route.params.idKomisiPayment,
        status: null
      }
    }
  },
  computed: {
    activeTab: {
      get () {
        return this.$store.state.approvals.komisiApproval.activeTab
      },
      set (value) {
        this.$store.commit('approvals/komisiApproval/SET_ACTIVE_TAB', value)
      }
    }
  },
  methods: {
    onSigned () {
      this.getInitData()
      this.$refs.approvalProgress.refresh()
    },

    getInitData () {
      this.isLoadingInitData = true

      const idKomisiPayment = this.$route.params.idKomisiPayment
      KomisiRepository.showPayment(idKomisiPayment)
        .then(response => {
          this.initData = response.data.data
        })
        .catch(error => {
          if (error.response.status === 404) {
            this.$router.push({ name: '404' })
          } else {
            console.log(error)
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoadingInitData = false
        })
    },

    onClickSigningAction (status) {
      this.modalSigning.status = status
      this.modalSigning.active = true
    }
  },
  beforeCreate () {
    this.$store.registerModule(['approvals', 'komisiApproval'], moduleKomisiApproval)
  },
  beforeDestroy () {
    this.$store.commit('approvals/komisiApproval/RESET_STATE')
    this.$store.unregisterModule(['approvals', 'komisiApproval'])
  }
}
</script>
